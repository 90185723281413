import React, { useState } from 'react';
import { css } from '@emotion/react';
import { randomColor, randomGoodColor } from './colors';

export default function Form({ className, title, altTitle, subtitle, fields, buttonText, invert, onSubmit, submitErrorMessage }) {
  const [formData, setFormData] = useState(fields.map((field) => ''));
  const [formValidBackground, setFormValidBackground] = useState(false);
  const [invalidInputsRed, setInvalidInputsRed] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [color, setColor] = useState(randomGoodColor());

  function handleInputChange(e, i) {
    let data = { ...formData };
    data[i] = e.target.value;
    setFormData(data);
  }

  function handleFocus() {
    setColor(randomGoodColor());
  }

  function handleFormChange(e) {
    const form = e.target.form;

    if (form.checkValidity()) {
      !formValidBackground && setFormValidBackground(randomGoodColor());
    } else {
      setFormValidBackground(false);
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    const form = e.target;

    if (!form.reportValidity()) {
      setInvalidInputsRed(true);
      setDisplayErrorMessage(true);
    } else {
      onSubmit(e, new FormData(form));
    }
  }

  return (
    <div
      className={className}
      css={css`
        background-color: ${formValidBackground ? formValidBackground : 'transparent'};
      `}
    >
      {title && <h3
        css={css`
          padding-bottom: var(--margin5);
          border-bottom: 4px solid var(--black);
          margin-bottom: var(--margin6);

          @media (max-width: 800px) {
            padding-bottom: var(--gutter);
            margin-bottom: var(--margin3);
          }

          @media (max-width: 550px) {
            padding-bottom: 2.3rem;
            margin-bottom: var(--gutter);
          }
        `}
      >
        {title}
      </h3>}
      {altTitle && <h3
          className="type--underline"
          css={css`
            text-align: center;
            margin-bottom: var(--margin8);
            padding-top: var(--margin6);
          `}
        >
          {altTitle}
      </h3>}
      {subtitle && <h4
        css={css`
          margin-bottom: var(--margin8);

          @media (max-width: 800px) {
            margin-bottom: var(--margin6);
          }

          @media (max-width: 550px) {
            margin-bottom: var(--margin3);
          }
        `}
      >
        {subtitle}
      </h4>}
      <form
        noValidate
        onSubmit={handleFormSubmit}
        onChange={handleFormChange}
        css={css`
          text-align: center;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            column-gap: var(--gutter);
            margin-bottom: var(--margin3);

            @media (max-width: 800px) {
              grid-template-columns: repeat(8, 1fr);
              column-gap: var(--gutterHalf);
              margin-bottom: var(--margin6);
            }

            @media (max-width: 600px) {
              display: block;
            }

            @media (max-width: 550px) {
              margin-bottom: 3.5rem;
            }

            input[type='text'],
            input[type='email'],
            input[type='tel'],
            select,
            textarea {
              grid-column-start: span 6;
              color: ${invalidInputsRed ? 'var(--red)' : invert ? 'var(--white)' : 'var(--black)'};
              border-color: ${invalidInputsRed ? 'var(--red)' : 'var(--gray-light)'};
              margin-bottom: 3.3rem;

              @media (max-width: 800px) {
                grid-column-start: span 4;
              }

              @media (max-width: 600px) {
                width: 100%;
                margin-bottom: var(--gutterHalf);
              }

              &::placeholder {
                color: ${invalidInputsRed ? 'var(--red)' : invert ? 'var(--gray-light)' : 'var(--black)'};
              }

              &:valid {
                color: ${invert ? 'var(--white)' : 'var(--black)'};
                border-color: ${invert ? 'var(--white)' : 'var(--black)'};

                &::placeholder {
                  color: ${invert ? 'var(--gray-light)' : 'var(--black)'};
                }

                &:placeholder-shown {
                  border-color: var(--gray-light);
                }
              }

              &:focus,
              &:focus:placeholder-shown {
                color: ${formValidBackground ? 'var(--black)' : color};
                border-color: ${formValidBackground ? 'var(--black)' : color};

                &::placeholder {
                  color: var(--gray-light);
                }
              }
            }

            textarea {
              grid-column-start: span 12;
              resize: none;
              padding: 4rem;
              border: 4px solid var(--gray-light);

              @media (max-width: 800px) {
                grid-column-start: span 8;
                padding: 2rem;
              }

              @media (max-width: 600px) {
                width: 100%;
              }
            }

            select {
              display: block !important;
              appearance: none;
              width: 100%;
              padding-top: 1.8rem;
              padding-bottom: 2.2rem;
            }

            .select {
              grid-column-start: span 6;
              padding-bottom: 0;
              position: relative;

              @media (max-width: 800px) {
                grid-column-start: span 4;
              }

              @media (max-width: 600px) {
                width: 100%;
                margin-bottom: var(--gutterHalf);
              }

              svg {
                position: absolute;
                top: 2rem;
                right: 0;

                @media (max-width: 1600px) {
                  width: 35px;
                }

                @media (max-width: 800px) {
                  top: 1rem;
                }

                @media (max-width: 600px) {
                  top: 0.5rem;
                  width: 30px;
                }
              }
            }
          `}
        >
          {fields?.map((field, i) => {
            if (field.type === 'textarea') {
              return <textarea
                key={field.name}
                className="type--paragraph"
                name={field.name}
                placeholder={field.placeholder}
                value={formData.name}
                onFocus={handleFocus}
                onChange={(e) => handleInputChange(e, i)}
                required={field.required}
                rows={8}
              ></textarea>
            } else if (field.type === 'select') {
              return <div
                className="select"
                key={field.name}
              >
                <select
                  className="type--heading-four"
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData.name}
                  onFocus={handleFocus}
                  onChange={(e) => handleInputChange(e, i)}
                  required={field.required}
                >
                  <option className="type--paragraph" value="" disabled selected>{field.placeholder}</option>
                  {field.options.length > 0 && field.options.map((option, index) => <option key={index} className="type--paragraph" value={option}>{option}</option>)}

                </select>
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="20.5" stroke="#EEEEEE" strokeWidth="3"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M22.0003 31.4559L24.1216 29.3346L34.7282 18.728L32.6069 16.6067L22.0003 27.2133L11.3937 16.6067L9.27234 18.728L19.8789 29.3346L22.0003 31.4559Z" fill="#EEEEEE"/>
                </svg>
              </div>
            } else {
              return (
                <input
                  key={field.name}
                  className="type--heading-four"
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData.name}
                  onFocus={handleFocus}
                  onChange={(e) => handleInputChange(e, i)}
                  required={field.required}
                />
              );
            }
          })}
        </div>
        <div
          className="type--paragraph"
          css={css`
            display: ${displayErrorMessage ? 'block' : 'none'};
            color: var(--red);
            margin-bottom: var(--margin3);
          `}
        >
          {submitErrorMessage}
        </div>
        <input
          className="type--paragraph"
          type="submit"
          value={(buttonText) ? buttonText : 'Submit'}
          css={css`
            display: inline-block;
            font-weight: normal;
            color: ${invert ? 'var(--white)' : 'var(--black)'};
            padding: 1.2rem 2.6rem 1rem;
            background-color: transparent;
            border: 4px solid ${invert ? 'var(--white)' : 'var(--black)'};
            border-radius: 44rem;
            margin-top: 2.3rem;
            cursor: pointer;

            &:hover {
              color: var(--white);
              background-color: var(--black);
            }
          `}
        />
      </form>
    </div>
  );
}

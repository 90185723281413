import React, { createContext, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [location, setLocation] = useState();
  const [headerHide, setHeaderHide] = useState(false);
  const [logoHide, setLogoHide] = useState(false);
  const [logoInvert, setLogoInvert] = useState(false);
  const [headerShowBack, setHeaderShowBack] = useState(false);
  const [backURL, setBackURL] = useState([]);
  const [collectionName, setCollectionName] = useState('Edit Collection Name');
  const [collection, setCollection] = useState(new Set());

  const settings = useStaticQuery(graphql`
    query {
      siteSettings: sanitySiteSettings(_id: { regex: "/(drafts\\\\.)?siteSettings/" }) {
        showModal
      }
    }
  `);

  return (
    <ThemeContext.Provider
      value={{
        location,
        setLocation,
        headerHide,
        setHeaderHide,
        logoHide,
        setLogoHide,
        logoInvert,
        setLogoInvert,
        headerShowBack,
        setHeaderShowBack,
        backURL,
        setBackURL,
        collectionName,
        setCollectionName,
        collection,
        setCollection,
        settings,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
